/* Footer Styles */
.footer {
    margin-top: 50px;
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .footer-nav {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
  }
  
  .footer-link {
    margin: 0 15px;
    text-decoration: none;
    font-size: 16px;
    color: #fff; /* White text */
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #00ff00; /* Green highlight on hover */
  }
  
  .footer-divider {
    border-top: 1px solid #fff; /* White horizontal divider */
    margin: 15px 0;
  }
  
  .footer-social {
    display: flex;
    margin-bottom: 15px;
    margin-top: 5px;
  }
  .logo{
    width: 40px;
    height: 40px;
  }
  
  .social-icon {
    margin: 0 10px;
    font-size: 20px;
    color: #fff; /* White social media icons */
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: #00ff00; /* Green highlight on hover */
  }
  
  .footer-copyright {
    font-size: 14px;
    color: #fff; /* White text */
    margin-top: 10px;
  }

  .footer-social-container{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }
  
  @media (max-width:768px){
    .footer {
      margin-top: 100px;
    }
    .footer-link {
    margin: 0 10px;

      font-size: 12px;
    }

    .footer-social-container{
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 50px;
    }

  }