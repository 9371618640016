/* Container for the testimonial section */
.testimonial-section {
    width: 98vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-color: #000000; /* White background */
  }
  
  .testimonial-container {
    display: flex;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Light shadow */
  }
  
  .testimonial-image{
    width: 40%;
    height: 95%;
  }
  /* Image Section */
  .testimonial-image img {
    width: 100%; 
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  
  /* Text Section */
  .testimonial-content {
    padding: 20px;
    width: 55%;
  }
  
  .testimonial-head{
    font-size: 50px;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    color: #ffffff;
  }
  .testimonial-text {
    font-size: 20px;
    color: #afaeae; /* Dark text color */
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .testimonial-author {
    font-size: 16px;
    color: #666; /* Light text color */
  }
  
  .testimonial-author h4 {
    margin: 0;
    font-size: 20px;
    color: #626060; /* Author name in darker color */
  }
  
  .testimonial-author p {
    margin: 5px 0 0 0;
    color: #555; /* Subtitle color */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .testimonial-section {
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      background-color: #000000; /* White background */
    }

    .testimonial-container {
      width: 100%;
    }

    .testimonial-image{
      margin-top: 150px;
      width: 95%;
      height: 95%;
    }
  
    .testimonial-image img {
      width: 100%;
      max-width: 350px;
    }
  
    .testimonial-content {
      padding: 20px;
      width: 100%;
    }

     
  .testimonial-head{
    font-size: 30px;
    width:100%;
    text-align:center
  }
  .testimonial-text {
    font-size: 16px;
    color: #afaeae; /* Dark text color */
    text-align: justify;
    width: 100%;

    margin-bottom: 30px;
  }
  
  }
  