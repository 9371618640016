/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #000; /* Black background */
  width: 98vw;
}

/* Logo styling */
.logo-container img {
  border-radius: 50%;
  border: 2px solid #00ff00; /* Green border */
  width: 60px;
  margin-left: 40px;
}

/* Web View Navigation Links */
.nav-web-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-web-links li {
  margin: 0 20px;
  cursor: pointer;
}

.nav-web-links li {
  text-decoration: none;
  font-size: 16px;
  color: #fff; /* White text color */
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-web-links li:hover {
  color: #00ff00; /* Green on hover */
}

/* Web View Buttons */
.nav-web-buttons {
  display: flex;
  gap: 20px; /* Space between buttons */
}

.hero-touch-button {
  background-color: transparent;
  border: 2px solid #00ff00; /* Green border */
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  font-weight: 500;
  color: #fff; /* White text color */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.hero-touch-button span {
  margin-left: 10px;
  font-size: 20px; /* Arrow icon */
}

.hero-touch-button:hover {
  background-color: #00ff00; /* Green background on hover */
  color: #000; /* Black text on hover */
}

/* Hamburger Icon */
.hamburger {
  display: none; /* Initially hidden */
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  transition: 0.3s;
}

/* Dialog Box */
.dialog-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

/* Mobile Navigation Links inside Dialog */
.nav-links-dialog {
  display: flex;
  flex-direction: column;
  list-style: none;
  text-align: center;
}

.nav-links-dialog li {
  margin: 20px 0;
  cursor: pointer;
}

.nav-links-dialog li {
  text-decoration: none;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-links-dialog li:hover {
  color: #00ff00;
  
}

/* Mobile Buttons inside Dialog */
.nav-buttons-dialog {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.hero-touch-button {
  background-color: transparent;
  border: 2px solid #00ff00;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
}

.hero-touch-button span {
  margin-left: 10px;
  font-size: 20px;
}

.hero-touch-button:hover {
  background-color: #00ff00;
  color: #000;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .nav-web-links, .nav-web-buttons {
      display: none; /* Hide nav links and buttons on smaller screens */
  }

  .hamburger {
      display: flex; /* Show hamburger icon for mobile */
  }
}

@media (max-width: 480px) {
  .hero-touch-button {
      font-size: 14px;
  }

  .hero-touch-button span {
      font-size: 18px;
  }

  .logo img {
      width: 35px; /* Smaller logo */
  }
}
