.login-signup-form-container{
    width: 98vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-signup-container{
    display: flex;
    border-radius: 20px;
    border: #545554 solid 1px;
    width: 90%;
}

.login-form{
    width: 45%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: transparent solid 1px;
}

.login-form h2{
    font-family: poppins;
    font-size: 30px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
}

.login-form input{
    background-color: transparent;
    height: 45px;
    padding-left: 20px;
    width: 80%;
    border-radius: 40px;
    border: #4eea36 solid 1px;
    margin-bottom: 15px;
}

.login-form textarea{
    background-color: transparent;
    width: 80%;
    padding: 20px;
    border-radius: 20px;
    border: #4eea36 solid 1px;
    margin-bottom: 15px;
}

.signup-button-container{
    background-image: url("../../../public/assets/cont.webp");
    background-size: cover;
    background-attachment: fixed;
    border-radius: 20px;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}

.hero1-touch-button {
    background-color: #00ff00;
    border: 2px solid #ffffff; /* Green border */
    font-size: 16px;
    cursor: pointer;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    font-weight: 500;
    color: #fff; /* White text color */
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .hero1-touch-button span {
    margin-left: 10px;
    font-size: 20px; /* Arrow icon */
  }
  
  .hero1-touch-button:hover {
    background-color: #00ff00; /* Green background on hover */
    color: #000; /* Black text on hover */
  }

  @media (max-width: 768px) {

    .login-signup-container{
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        border: #545554 solid 1px;
        width: 90%;
    }

    .login-form h2{
        font-family: poppins;
        font-size: 20px;
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 20px;
        padding: 20px;
        text-align: center;
    }
    
    .login-form{
        width: 100%;
    }

    .signup-button-container{
        width: 100%;
  }
  .hero1-touch-button {
    margin-top: 20px;
    margin-bottom: 20px;
  }

}