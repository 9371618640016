.total-card-tech-container {
    width: 98vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    flex-wrap: wrap; /* Ensures cards adjust in smaller screens */
  }
  
  .total-card-1-container {
    width: 30%;
    background-image: linear-gradient(163deg, #00ff75 0%, #3700ff 100%);
    border-radius: 20px;
    transition: all .3s;
    margin: 10px;
    height: 400px; /* Fixed height for each container */
  }
  
  .total-card-1 {
    /* background-image: url("../../../public/assets/bg.webp");
    background-size: cover;
    opacity: 50%; */
    height: 100%;
    background-color: #1a1a1a;
    border-radius: 10px;
    transition: all .2s;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .total-card-1:hover {
    transform: scale(0.98);
    border-radius: 20px;
  }
  
  .total-card-1-container:hover {
    padding: 1px;
    box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.30);
  }
  
  .total-card-1-title {
    font-size: 30px;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .total-card-1-text {
    text-align: center;
    font-size: 16px;
    color: #afaeae;
  }

  .card-web-logo{
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  .card-and-logo{
    width: 120px;
    height: 80px;
    margin-bottom: 10px;
  }
  
  .card-dm-logo{
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    mix-blend-mode: screen;
  }

  .total-card-heading{
    font-size: 60px;
    font-weight: bold;
    width: 90%;
    text-align: center;
    font-family: poppins;
    line-height: 92px;
    color: #ffffff;
  }

  @media (max-width:768px){
    .total-card-tech-container {
      width: 98vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px;
    }
    .total-card-1-container {
      width: 100%;
      height: 400px; /* Fixed height for each container */
    }
    
    .total-card-1 {
      width: 100%;
    }

    .total-card-heading{
      font-size: 40px;
      text-align: center;
      width: 100%;
      margin-top: 50px;
    }
  
  }