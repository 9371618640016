.hero-container{
    background: radial-gradient(#323232, black)!important;
    height: 100vh ;
}
.hero-heading{
    /* color: #CCCCCC; */
    font-size: 80px;
    font-weight: 500;
    width: 80%;
    text-align: center;
    font-family: poppins;
    line-height: 92px;
    background: radial-gradient(#ffffff, #686666); /* Define your gradient here */
    -webkit-background-clip: text; /* This clips the background to the text */
    -webkit-text-fill-color: transparent; /* This makes the text color transparent */
    background-clip: text;
    opacity: 0; /* Start invisible */
    animation: textReveal 1.5s ease-out forwards; /* Apply the reveal animation */
}

@keyframes textReveal {
    0% {
      opacity: 0;
      transform: translateY(30px); /* Start below the original position */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End in the original position */
    }
  }
  

.hero-para-button-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-para{
    margin-top: 20px;
    font-size: 18px;
    width: 40%;
    text-align: center;
    font-family: inter, "inter-placeholder", sans-serif;
    background: radial-gradient(#ffffff, #686666); /* Define your gradient here */
    -webkit-background-clip: text; /* This clips the background to the text */
    -webkit-text-fill-color: transparent; /* This makes the text color transparent */
    background-clip: text;
    text-align: center;
    opacity: 0;
    animation: fadeUp 1s ease-in forwards;
}
.hero-touch-button{
    margin-top: 20px;
    height: 52px;
    width: 160px;
    font-weight: 500;
    padding: 16px 28px 16px 28px;
    font-family: sans-serif;
    font-size: 14px;
    color: #4eea36;
    border: #4eea36 solid 1px;
    border-radius: 50px;
    opacity: 0;
    margin-right: 10px;
    animation: fadeUp 1s ease-in forwards;
}

.hero-touch-button:hover{
    box-shadow: 0 0 2px #4eea36,
              0 0 5px #4eea36,
              0 0 8px #4eea36,
              0 0 10px #4eea36;
    color: #ffffff;
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width:768px){
    .hero-heading{
        /* color: #CCCCCC; */
        font-size: 40px;
        font-family: poppins;
        line-height: 42px;
        width: 80%;
    }
    .hero-para{
        margin-top: 20px;
        font-size: 14px;
        width: 60%;
    }
    .hero-touch-button{
        width: 140px;
    }
    
}