.slideshow-container{
    height: 100vh;
    padding: 30px;
}

.slideshow-heading {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
    color: #ffffff;
    animation: showup 2s ease-out forwards; /* Adds the showup animation */
}

/* Keyframes for the showup animation */
@keyframes showup {
    0% {
        opacity: 0;        /* Initially invisible */
        transform: translateY(50px); /* Start below the original position */
    }
    100% {
        opacity: 1;        /* Fully visible */
        transform: translateY(0); /* Moves to the original position */
    }
}


.entire-content {
    margin: auto;
    width: 350px; /* Increased width to allow for larger images */
    perspective: 1500px; /* Increase perspective for better 3D effect */
    position: relative;
    padding-top: 80px;
  }
  
  .content-carrousel {
    width: 100%;
    position: absolute;
    float: right;
    animation: rotar 15s infinite linear;
    transform-style: preserve-3d;
  }
  
  .content-carrousel:hover {
    animation-play-state: paused;
    cursor: pointer;
  }
  
  .content-carrousel figure {
    width: 320px; /* Increased width */
    height: 220px; /* Increased height */
    border: 1px solid #3b444b;
    overflow: hidden;
    position: absolute;
  }
  
  /* Adjust the rotation and translation for the increased radius */
  .content-carrousel figure:nth-child(1) {
    transform: rotateY(0deg) translateZ(500px); /* Increased translateZ for larger radius */
  }
  .content-carrousel figure:nth-child(2) {
    transform: rotateY(40deg) translateZ(500px);
  }
  .content-carrousel figure:nth-child(3) {
    transform: rotateY(80deg) translateZ(500px);
  }
  .content-carrousel figure:nth-child(4) {
    transform: rotateY(120deg) translateZ(500px);
  }
  .content-carrousel figure:nth-child(5) {
    transform: rotateY(160deg) translateZ(500px);
  }
  .content-carrousel figure:nth-child(6) {
    transform: rotateY(200deg) translateZ(500px);
  }
  .content-carrousel figure:nth-child(7) {
    transform: rotateY(240deg) translateZ(500px);
  }
  .content-carrousel figure:nth-child(8) {
    transform: rotateY(280deg) translateZ(500px);
  }
  .content-carrousel figure:nth-child(9) {
    transform: rotateY(320deg) translateZ(500px);
  }
  .content-carrousel figure:nth-child(10) {
    transform: rotateY(360deg) translateZ(500px);
  }
  
  .shadow {
    position: absolute;
    box-shadow: 0px 0px 20px 0px #000;
    border-radius: 1px;
  }
  
  .content-carrousel img {
    image-rendering: auto;
    transition: all 300ms;
    width: 100%;
    height: 100%;
  }
  
  .content-carrousel img:hover {
    transform: scale(1.2);
    transition: all 300ms;
  }
  
  @keyframes rotar {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }

  .slideshow-para{
    color: #ffffff;
    width: 90%;
    padding-left: 40px;
    text-align: center;
  }
  

  @media (max-width: 768px ){
    .entire-content {
      margin: auto;
      width: 100px; /* Increased width to allow for larger images */
      perspective: 1200px; /* Increase perspective for better 3D effect */
      position: relative;
      padding-top: 80px;
    }

    .content-carrousel figure {
      width: 100px; /* Increased width */
      height: 100px; /* Increased height */
      border: 1px solid #3b444b;
      overflow: hidden;
      position: absolute;
    }
     /* Adjust the rotation and translation for the increased radius */
  .content-carrousel figure:nth-child(1) {
    transform: rotateY(0deg) translateZ(150px); /* Increased translateZ for larger radius */
  }
  .content-carrousel figure:nth-child(2) {
    transform: rotateY(40deg) translateZ(150px);
  }
  .content-carrousel figure:nth-child(3) {
    transform: rotateY(80deg) translateZ(150px);
  }
  .content-carrousel figure:nth-child(4) {
    transform: rotateY(120deg) translateZ(150px);
  }
  .content-carrousel figure:nth-child(5) {
    transform: rotateY(160deg) translateZ(150px);
  }
  .content-carrousel figure:nth-child(6) {
    transform: rotateY(200deg) translateZ(150px);
  }
  .content-carrousel figure:nth-child(7) {
    transform: rotateY(240deg) translateZ(150px);
  }
  .content-carrousel figure:nth-child(8) {
    transform: rotateY(280deg) translateZ(150px);
  }
  .content-carrousel figure:nth-child(9) {
    transform: rotateY(320deg) translateZ(150px);
  }
  .content-carrousel figure:nth-child(10) {
    transform: rotateY(360deg) translateZ(150px);
  }
  
  .slideshow-para{
    font-size: 14px;
    
    margin-bottom: 50px;
  }

  .slideshow-container{
    padding: 30px;
    margin-bottom: 60px;
    height: auto;
}

  }